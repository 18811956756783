<template>
    <div class="financial_sharing">
        <!-- <h1 class="tradeno">点击以下按钮唤起收银台支付</h1>
        <a href="javascript:void(0)" class="btn tradenoPay">支付宝交易号唤起支付</a>     -->

    </div>
</template>
<script src="https://gw.alipayobjects.com/as/g/h5-lib/alipayjsapi/3.1.1/alipayjsapi.min.js"></script>
<script>
import { Toast } from 'vant';
    export default {
        data () {
            return {
                userOrder: '', //用户订单
                tradeNO: '', //授权成功后获取的订单编号
                //订单信息
                    orderId: '', //订单流水号
                    isPay: '', //是否支付完成
                    app_id: '', //获取授权的id
                    scope: '', //获取授权的scope
                    redirect_uri: '', //回调的url
                    response_type: '', //获取微信授权的response_type
                
            }
        },
        mounted() {
            // alert(window.location.href,'window.location.hrefwindow.location.href')
            // this.checkIsAuthCode()
        },
        methods: {
            //判断是否有auth_code
            checkIsAuthCode() {
                if(window.location.href.split('auth_code')[1]) {
                    //如果有，说明已经授权过，直接获取auth_code调用获取支付宝订单信息接口
                    let authCode = ''
                    authCode = window.location.href.split('auth_code')[1].split('=')[1];
                    this.pay(authCode, this.userOrder)
                } else {
                    //如果没有判断当前url是否有用户订单信息
                    if(window.location.href.split('userOrder')[1]) {
                        //如果有获取用户订单信息拼接到回调的url后面
                        this.userOrder = window.location.href.split('userOrder')[1].split('=')[1];
                        this.redirect_uri = `http://www.yanggb.com?${this.userOrder}`
                        this.goAlipaySilentAuthorization()
                    } else {
                        //如果没有弹出错误信息
                        alert(window.location.href, '订单信息可能有误！')
                    }
                    
                }
            },
            //调转到支付宝的静默授权地址
            goAlipaySilentAuthorization() {
                window.location.href = (`https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=${this.app_id}&redirect_uri=${this.redirect_uri}&scope=${this.scope}`); 
            },           
            //通过获取的auth_code和用户订单信息调取后端接口获取支付宝订单信息
            pay(authCode, userCode) {
                this.$http.formData(this.$api.postPay('', 'CESHI_API_HOST'), {
                    auth_code: authCode,
                    user_code: userCode
                    
                }).then((res) => {  
                    alert(res, '通过静默授权返回参数获取后端的支付宝订单号')
                    if(res.data.code == 200) {
                        this.tradeNO = res.data.data.tradeNO;
                        this.ready(this.readyPay)
                    } else {

                        Toast(res.data.msg);
                    }
                })
            },
            //支付宝支付
            ready(callback) {
                alert(window.AlipayJSBridge,'进入支付宝支付')
                // 如果jsbridge已经注入则直接调用
                if (window.AlipayJSBridge) {
                    AlipayJSBridge.call("tradePay", {
                        tradeNO: this.tradeNO
                    }, 
                    function(result) {
                        alert(JSON.stringify(result));
                    });
                    callback && callback();
                } else {
                    document.addEventListener('AlipayJSBridgeReady', callback, false);
                }
            },
            readyPay() {
                console.log('进入readyPay！')
                document.querySelector('.tradeno').addEventListener('click', function() {
                    AlipayJSBridge.call("tradePay", {
                        tradeNO: this.tradeNO
                    }, function(result) {
                        alert(JSON.stringify(result));
                    });
                });
            },















            //唤起微信支付
            // payWeiXin(e) {
            //     this.$http.formData(this.$api.postPay('', 'CESHI_API_HOST'), {
            //         auth_code: e
            //     }).then((res) => {  
            //         if(res.data.code == 200) {
            //             this.wxParams = res.data.data;
            //             this.wxReady(wxParams)
            //         } else {
            //             Toast(res.data.msg);
            //         }
            //     })
            // },
            //微信支付
            // wxReady(data) {
            //     var vm= this;
            //     if (typeof WeixinJSBridge == "undefined"){
            //         if( document.addEventListener ){
            //             document.addEventListener('WeixinJSBridgeReady', vm.onBridgeReady(data), false);
            //         }else if (document.attachEvent){
            //             document.attachEvent('WeixinJSBridgeReady', vm.onBridgeReady(data));
            //             document.attachEvent('onWeixinJSBridgeReady',vm.onBridgeReady(data));
            //         }
            //     }else{
            //         vm.onBridgeReady();
            //     }

            // },
            // onBridgeReady(data) {
            //     if (typeof WeixinJSBridge === 'undefined') {
            //        Toast({ message: '请使用微信内置浏览器进行支付' })
            //     } else {
            //         WeixinJSBridge.invoke(
            //             'getBrandWCPayRequest',
            //             {
            //                 appId: data.appId, // 公众号名称，由商户传入
            //                 timeStamp: data.timeStamp, // 时间戳，自1970年以来的秒数
            //                 nonceStr: data.nonceStr, // 随机串
            //                 package: data.package,
            //                 signType: data.signType, // 微信签名方式：
            //                 paySign: data.paySign // 微信签名
            //             },
            //             res => {
            //                 if (res.err_msg === 'get_brand_wcpay_request:ok') {
            //                    Toast({ message: '支付成功' })
            //                     //this.$router.push({path: '/videoplayer', query: { video_id: this.$route.query.video_id }}) // 支付成功之后跳转的路由
            //                 } else {
            //                    Toast({ message: '支付失败' })
            //                 }
            //             }
            //         )
            //     } 
            // },

        }
    }
</script>

<style  lang='scss' scoped>

.financial_sharing {
    background: #ffffff;
    width: 100%;
    height: 100%;
    overflow: hidden;
    .back_title {
        width: 100%;
        height: 88px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 34px;
        font-weight: 600;
        color: #000000;
        position: relative;
        i {
            position: absolute;
            left: 24px;
            color: #000000;
        }
    }
    .financial_sharing_top {
        .my-swipe .van-swipe-item {
            color: #fff;
            font-size: 20px;
            line-height: 600px;
            text-align: center;
            background-color: #39a9ed;
        }

        
    }

}
</style>